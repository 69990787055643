/* eslint-disable spellcheck/spell-checker */
import 'shared/css/paf-bootstrap.scss';
import SubsurfaceInsightsLogo from 'SubsurfaceInsights_Logo_Final.png';
import BackgroundImage from 'login-background.jpg';
import BottomLeftImage from 'website-figure.png';
import 'bootstrap';
import React, { useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';

// importing the pieces that will be used by this page
import CheckACLAndInitNav from 'shared/js/check-acl-init-nav.js';
import { ListGroup, Spinner, Container, Row,
         Col, Card, Table, Modal, Button, Form, Alert, InputGroup, FormControl, Stack} from 'react-bootstrap';
import PAF from 'shared/js/paf';

const SUPPORT_EMAIL = 'roelof.versteeg@subsurfaceinsights.com';

function passLogin() {
  // Get the redir query parameter
  // If it's not set, redirect to /report
  const url = new URL(window.location.href);
  const redir = url.searchParams.get('redir');
  if (redir) {
    window.location.href = redir;
  } else {
    window.location.href = '/report';
  }
}

function EmailMfaForm() {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!loading) {
      return;
    }
    fetch('/api/auth/send_email_verification', {
    }).then(async (response) => {
      setLoading(false);
      console.log(response.status);
      switch (response.status) {
        case 200:
          // Do a websocket connection to get the OTP
          const websocket = PAF.WebSocket('/ws/auth/mfa_wait_verification');
          websocket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.mfa_passed == true) {
              passLogin();
            } else {
              setError('Login Timeout. Please try again.');
            }
          };
          websocket.onclose = (event) => {
            console.log('Websocket closed');
            setError('Network error?');
          }
          break;
        case 401:
          setError(await response.text());
          break;
        default:
          setError('Unknown error: ' + response.status);
        };
      });
  }, []);
  if (loading) {
    return (
      <Spinner animation="border" role="status" />
    );
  }
  if (error) {
    return (
      <>
        <Alert variant="danger">{error}
        </Alert>
        <p>Contact support at <a href={"mailto:" + SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a></p>
      </>
    );
  }
  else {
    return (
      <Alert variant="info">
        <p>
          We have sent a one-time login link to your email address that expires in 10 minutes.
        </p>
        <p>
          Please check your email and click the link to login.
        </p>
      </Alert>
    );
  }
}

function TOTPDigit({onInput, moveNext, movePrev, ...props}) {
  const [value, setValue] = useState<string>('');
  return (
    <input
      class="form-control"
      type="text"
      style={{
        textAlign: 'center',
      }}
      maxLength={1}
      value={value}
      {...props}
      onChange={(e) => {
        const value = e.target.value;
        if (value === '') {
          setValue('');
          movePrev();
          return;
        }
        // Check that it's a number
        if (value.match(/^\d+$/)) {
          console.log(typeof onInput);
          onInput(parseInt(value));
          setValue(value);
          moveNext();
        }
      }}
    />
  );
}

function TOTPInput({onSubmit}) {
  const [value, setValue] = useState<number[]>([0, 0, 0, 0, 0, 0]);
  return (
    <InputGroup style={{}}>
      <TOTPDigit
        id="totp0"
        onInput={(v) => setValue([v, value[1], value[2], value[3], value[4], value[5]])}
        moveNext={() => document.getElementById('totp1').focus()}
        movePrev={() => void 0}
        />
      <TOTPDigit
        id="totp1"
        onInput={(v) => setValue([value[0], v, value[2], value[3], value[4], value[5]])}
        moveNext={() => document.getElementById('totp2').focus()}
        movePrev={() => document.getElementById('totp0').focus()}
        />
      <TOTPDigit
        id="totp2"
        onInput={(v) => setValue([value[0], value[1], v, value[3], value[4], value[5]])}
        moveNext={() => document.getElementById('totp3').focus()}
        movePrev={() => document.getElementById('totp1').focus()}
        />
      <TOTPDigit
        id="totp3"
        onInput={(v) => setValue([value[0], value[1], value[2], v, value[4], value[5]])}
        moveNext={() => document.getElementById('totp4').focus()}
        movePrev={() => document.getElementById('totp2').focus()}
        />
      <TOTPDigit
        id="totp4"
        onInput={(v) => setValue([value[0], value[1], value[2], value[3], v, value[5]])}
        moveNext={() => document.getElementById('totp5').focus()}
        movePrev={() => document.getElementById('totp3').focus()}
        />
      <TOTPDigit
        id="totp5"
        onInput={(v) => {
        setValue([value[0], value[1], value[2], value[3], value[4], v])
        value[5] = v;
        // Submit the form if all 6 digits are entered
        onSubmit(value.join(''));}}
        movePrev={() => document.getElementById('totp4').focus()}
        moveNext={() => void 0}
        />
    </InputGroup>
  );
}

function TOTPForm({onLostAccess}) {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  return (
  <>
    <Form>
      {error ? <Alert variant="danger">{error}</Alert> : null}
      <Form.Group controlId="totp">
        <Form.Label>Auth Token</Form.Label>
        <TOTPInput onSubmit={(token) =>{
          setLoading(true);
          fetch('/api/auth/verify_totp', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              'totp_code': token,
            }),
          }).then(async (response) => {
            setLoading(false);
            switch (response.status) {
              case 200:
                const data = await response.text();
                passLogin();
                break;
              case 401:
                setError(await response.text());
                setTimeout(() => setError(null), 1000);
                break;
              default:
                console.log('Unknown error: ' + response.status);
            };
          });
        }}/>
      </Form.Group>
      {loading ? <Spinner animation="border" role="status" /> : null}
      <Form.Text className="text-muted">
        <a href="#" onClick={onLostAccess} >I Lost Access to my Auth Device</a>
      </Form.Text>
    </Form>
  </>
  );
}

function ForgotPasswordForm() {
  return (
    <Form>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
      </Form.Group>
      <Button variant="primary" type="submit">
        Send Reset Email
      </Button>
    </Form>
  );
}


function RegisterForm({email, registerToken, onSuccess, onError}) {
  // We want to show the email which will already be verified
  // but not let the user change it
  return (
    <Form onSubmit={(form) => {
      form.preventDefault();
      const formData = form.currentTarget.elements;
      const firstName = formData['formBasicFirstName'].value;
      const lastName = formData['formBasicLastName'].value;
      const password = formData['formBasicPassword'].value;
      const passwordConfirm = formData['formBasicPasswordConfirm'].value;
      if (firstName === '' || lastName === '' || password === '' || passwordConfirm === '') {
        onError('Please fill in all fields');
        return;
      }
      if (password !== passwordConfirm) {
        onError('Passwords do not match');
        return;
      }
      fetch('/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          register_token: registerToken,
          email,
          first_name: firstName,
          last_name: lastName,
          password,
        }),
      }).then(async (response) => {
        switch (response.status) {
          case 200:
            onSuccess('Registration successful');
            break;
          case 401:
            onError('Invalid email');
            break;
          default:
            onError('Unknown error: ' + response.status + ' ' + await response.text());
        };
      });
    }}>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" value={email} readOnly />
      </Form.Group>
      <Form.Group controlId="formBasicFirstName">
        <Form.Label>First Name</Form.Label>
        <Form.Control type="text" placeholder="First Name" />
      </Form.Group>
      <Form.Group controlId="formBasicLastName">
        <Form.Label>Last Name</Form.Label>
        <Form.Control type="text" placeholder="Last Name" />
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Password" />
      </Form.Group>
      <Form.Group controlId="formBasicPasswordConfirm">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control type="password" placeholder="Confirm Password" />
      </Form.Group>
      <Button variant="primary" type="submit">
        Register
      </Button>
    </Form>
  );
}

function RequestAccessForm(onSuccess, onError) {
  return (
    <Form
      onSubmit={(form) => {
        form.preventDefault();
        const formData = form.currentTarget.elements;
        const email = formData['formBasicEmail'].value;
        if (email === '') {
          onError('Please enter email');
          return;
        }
        fetch('/api/auth/request_access', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
          }),
        }).then(async (response) => {
          switch (response.status) {
            case 200:
              onSuccess('Access request sent');
              break;
            case 401:
              onError('Invalid email');
              break;
            default:
              onError('Unknown error: ' + response.status + ' ' + await response.text());
          };
        });
      }}>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
      </Form.Group>
      <Button variant="primary" type="submit">
        Request Access
      </Button>
    </Form>
  );
}

function OTPLoginForm() {
  return (
    <Form>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>OTP</Form.Label>
        <Form.Control type="text" placeholder="Enter OTP" />
      </Form.Group>
      <Button variant="primary" type="submit">
        Login
      </Button>
    </Form>
  );
}

function LoginForm({onForgotPassword, onRequestAccess, onLogin}) {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    return (
        <>
          <Stack gap={3} className="mx-auto">
            {error ? <Alert variant="danger">{error}</Alert> : null}
            <Form
              onSubmit={(form) => {
                form.preventDefault();
                const formData = form.currentTarget.elements;
                const email = formData['loginEmail'].value;
                const password = formData['loginPassword'].value;
                if (email === '' || password === '') {
                  setError('Please enter email and password');
                  return;
                }
                setLoading(true);
                fetch('/api/auth/login', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    email,
                    password,
                  }),
                }).then(async (response) => {
                  switch (response.status) {
                    case 200:
                      //const data = await response.text();
                      setError(null);
                      onLogin(null);
                      break;
                    case 401:
                      setError('Invalid email or password');
                      break;
                    case 403:
                      // MFA required
                      setError(null);
                      const json = await response.json();
                      onLogin(json.mfa_method);
                      break;
                    default:
                      setError('Unknown error: ' + response.status);
                  };
                  setLoading(false);
                });
              }}
              action="#">
                <Form.Group controlId="loginEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    disabled={loading}
                    type="email"
                    placeholder="Enter email" />
                </Form.Group>
                <Form.Group controlId="loginPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    disabled={loading}
                    type="password" placeholder="Password" />
                </Form.Group>
                <Form.Group controlId="loginRememberMe">
                  <Form.Check type="checkbox" label="Remember Me" />
                </Form.Group>
                <Button disabled={loading} variant="primary" type="submit" className='w-100'>
                  {loading ? <Spinner animation="border" role="status" /> : 'Login'}
                </Button>
                <Form.Text className="text-muted">
                  <a href="#" onClick={onForgotPassword} >Forgot Password</a>
                </Form.Text>
                <Form.Text className="text-muted">
                  <a href="#" onClick={onRequestAccess} >Request Access</a>
                </Form.Text>
            </Form>
          </Stack>
        </>
    );
}

function FormWithBackButton({children, onBack}) {
  return (
    <Form>
      <Button variant="primary" type="submit" onClick={onBack}>
        Back
      </Button>
      {children}
    </Form>
  );
}


function AuthenticationArea({mode, onSuccess, onError}: {mode: string | null, onSuccess: (msg: string) => void, onError: (msg: string) => void}) {
  const [loginState, setLoginState] = useState(mode || 'login');
  switch (loginState) {
    case 'login':
      return (
            <LoginForm
              onForgotPassword={() => setLoginState('forgot-password')}
              onRequestAccess={() => setLoginState('request-access')}
              onLogin={(mfaMethod) => {
                if (mfaMethod) {
                  setLoginState(mfaMethod);
                } else {
                  passLogin();
                }
              }}
            />
      );
    case 'forgot-password':
      return (
          <FormWithBackButton onBack={() => setLoginState('login')}>
            <ForgotPasswordForm />
          </FormWithBackButton>
      );
    case 'request-access':
      return (
          <FormWithBackButton onBack={() => setLoginState('login')}>
            <RequestAccessForm
              onSuccess={onSuccess}
              onError={onError} />
          </FormWithBackButton>
      );
    case 'email':
      return (
        <EmailMfaForm />
      );
    case 'sms':
      return (
          <FormWithBackButton onBack={() => setLoginState('login')}>
            <Alert variant="danger">SMS OTP not implemented</Alert>
          </FormWithBackButton>
      );
    case 'totp':
      return (
          <TOTPForm onLostAccess={() =>  onSuccess(
            'Please contact support <a href="mailto:' + SUPPORT_EMAIL + '">' + SUPPORT_EMAIL + '</a>'
          )}/>
      );
    default:
      return (
          <FormWithBackButton onBack={() => setLoginState('login')}>
            <Alert variant="danger">Unknown MFA method: {loginState}</Alert>
          </FormWithBackButton>
      );
    }
}

function addQueryParams(url, params) {
  if (url === null) {
    return null;
  }
  // Maybe just a path
  if (url.indexOf('http') !== 0) {
    url = window.location.origin + url;
  }
  const urlObj = new URL(url);
  for (const key in params) {
    urlObj.searchParams.set(key, params[key]);
  }
  return urlObj.toString();
}

function Layout({project, children}) {
  // We need to produce a layout with a grid with logos in each corner
  // and the center area is a login form
  let backgroundUrl = project.project_background_url || BackgroundImage;
  // If we are in 2x mode, we use 3840
  const screenWidth = window.visualViewport.scale === 2 ? 3840 : 1920;
  const logoWidth = screenWidth / 3;
  backgroundUrl = addQueryParams(backgroundUrl, {width: screenWidth});
  console.log(project);
  const topLeftLogo = addQueryParams(project.project_logo_topleft_url, {width: logoWidth});
  const bottomLeftLogo = addQueryParams(project.project_logo_url, {width: logoWidth});
  return (
      <div
        style={{
          backgroundImage: `url(${backgroundUrl})`,
          backgroundSize: 'cover',
          width: '100vw',
          height: '100vh',
          position: 'relative',
          display: 'flex',
        }}
      >
          <img
            className='position-absolute'
            style={{
              maxWidth: '25vw',
              top: '0',
              left: '0',
            }}
            src={topLeftLogo}/>
          <div
            className='position-absolute'
            style={{
              backgroundColor: 'RGBA(255, 255, 255, 0.33)',
              maxWidth: '25vw',
              top: '3vw',
              right: '3vw',
              padding: '1em',
              borderRadius: '1em',
            }}
          >
            <h3>Project Contact</h3>
            <p>
            {project.contact_name}
            </p>
            <p>
            <a href={"mailto:" + project.contact_email}>{project.contact_email}</a>
            </p>
          </div>
          <div
            // Center the login form in the middle of the screen
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'RGBA(255, 255, 255, 0.75)',
              padding: '1em',
              borderRadius: '1em',
              boxShadow: '0 0 1em 0.5em RGBA(0, 0, 0, 0.5)',
              width: '33vw',
              minWidth: '300px',
              className: '3m 3p',
            }}
          >
            <center><h3>{project.name}</h3></center>
            {children}
          </div>
          <img
            className='position-absolute'
            style={{
              maxWidth: '25vw',
              bottom: '0',
              left: '0',
              backgroundColor: 'RGBA(255, 255, 255, 0.25)',
              padding: '1em',
              borderRadius: '1em',
            }}
            src={bottomLeftLogo} alt="Website Figure" />
          <a href="https://www.subsurfaceinsights.com">
            <img
              className='position-absolute'
              src={SubsurfaceInsightsLogo} alt="Subsurface Insights"
              style={
                {
                  maxWidth: '30vw',
                  bottom: '0',
                  right: '0',
                  backgroundColor: 'RGBA(255, 255, 255, 0.75)',
                }
              }/>
          </a>
      </div>
    );
}



function Login() {
  const [email, setEmail] = useState(null as string | null);
  const [userQueried, setUserQueried] = useState(false as boolean);
  const [project, setProject] = useState(null);
  const [error, setError] = useState<string | null>(null);
  const [notification, setNotification] = useState<string | null>(null);
  if (notification) {
    return (
      <Alert variant="info" onClose={() => setNotification(null)} dismissible>
        <div dangerouslySetInnerHTML={{__html: notification}} />
      </Alert>
    );
  }
  if (error) {
    return (
      <Alert variant="danger" onClose={() => setError(null)} dismissible>
        {error}
      </Alert>
    );
  }
  useEffect(() => {
    fetch('/api/project/v2/get_current_project')
      .then(data => data.json().then(data => {
        setProject(data);
      }));
  } , []);
  const url = new URL(window.location.href);
  const mode = url.searchParams.get('m');
  const registerToken = url.searchParams.get('r');
  useEffect(() => {
    if (!registerToken) {
      fetch('/api/user/v2/get_current_user')
        .then(data => {
          if (data.status === 200) {
            if (!mode) {
              window.location.href = '/project-info';
            } else {
              data.json().then(data => {
                setEmail(data.email);
                setUserQueried(true);
              });
            }
          } else {
            setUserQueried(true);
          }
        });
    } else {
      fetch('/api/auth/verify_register_token/{registerToken}').then(async (response) => {
        switch (response.status) {
          case 200:
            response.text().then(data => {
              setEmail(data);
              setUserQueried(true);
            });
            break;
          case 401:
            setError('Invalid token');
            break;
          default:
            setError('Unknown error: ' + response.status);
        };
      });
    }
  }, []);
  if (project === null || userQueried === false) {
    return (
      <Container>
        <Row>
          <Col>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  }
  if (registerToken) {
    return (
      <Layout project={project}>
        <RegisterForm
          email={email}
          registerToken={registerToken}
          onSuccess={(msg) => {
            setNotification(msg);
            setTimeout(() => passLogin(), 1000);
          }}
          onError={(msg) => setError(msg)}
        />
      </Layout>
    );
  }
  else {
    return (
      <Layout project={project}>
        <AuthenticationArea
          mode={mode}
          onSuccess={(msg) => setNotification(msg)}
          onError={(msg) => setError(msg)}
          />
      </Layout>
    );
  }
}

function EmailVerification({token}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [project, setProject] = useState(null);
  const [passed, setPassed] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    fetch('/api/project/v2/get_current_project').then(data => data.json().then(data => {
      setProject(data);
    }));
    fetch('/api/auth/verify_email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        otp_token: token,
      }),
    }).then(async (response) => {
      setLoading(false);
      switch (response.status) {
        case 200:
          setPassed(true);
          break;
        case 401:
        case 403:
          setError('Login invalid or expired. Please try again');
          break;
        default:
          setError('Unknown error: ' + response.status);
      };
    });
  }, []);
  if (!project) {
    return (
      <Spinner animation="border" role="status" />
    );
  }
  if (loading) {
    return (
      <Layout project={project}>
      <Spinner animation="border" role="status" />
      </Layout>
    );
  }
  if (error) {
    return (
      <Layout project={project}>
      <Alert variant="danger">{error}
      </Alert>
      <p>Contact support at <a href={"mailto:" + SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a></p>
      </Layout>
    );
  }
  if (passed) {
    return (
      <Layout project={project}>
        <Alert variant="success">Verification successful. Check your login page</Alert>
      </Layout>
    );
  }
}

const App = () => {
  // Get the otp_token login parameter from the query string
  const url = new URL(window.location.href);
  const otp_token = url.searchParams.get('otp_token');
  if (otp_token) {
    return (
      <EmailVerification
        token={otp_token}/>
    );
  }
  return (
    <Login />
  );
}

const container = document.createElement('div');
container.setAttribute('id', 'paf-empty');
const body = document.getElementsByTagName('body')[0];
body.appendChild(container);
const root = ReactDOM.createRoot(container);
root.render(<App />);
